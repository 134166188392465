//import ImageOne from '../images/imgInfo-1.jpg';
import ImageTow from '../images/zefat.jpeg';

export const InfoData = {
    heading: "השמים הם הגבול...אבל כשהם פתוחים אין גבול",
    paragraphOne: `
        במתחם 'שמים פתוחים' הושקעה השקעה נדירה לפרטי פרטים,
        וכל זאת במטרה להעניק לכם חווית נופש מושלמת ופינוק מקסימלי.
        מתחם 'שמים פתוחים' שוכן בנקודה אסטרטגית בלבה של צפת.
        מצד אחד מרכז עירוני, הכולל חנויות ומסעדות ברוח צפתית.
        ומצד שני סמטאות העיר העתיקה ורובע בתי הכנסת.
     
     `,
    paragraphTow: `
        חלק מבתי הכנסת פעילים בעיקר בשבתות וחגים,
        וחלקם גם בימות החול.
        לנוחיותכם מצורפת מפת התמצאות של האיזור להורדה,
        בה מסומנים בתי הכנסת, מסעדות, וחנויות שימושיות 
    `,
    buttonLabel: 'צור קשר',
    image: ImageTow,
    revers: false,
    delay: 100
};

